@use "../scss/variables.module.scss";

.edit-mode-title {
  background-color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;

  p {
    margin-right: 20px;
    color: #fff;
  }
}

.edit-gallery {
  .edit-loader {
    height: 380px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  .gallery-showcase-container {
    margin-bottom: 5px;
    .gallery-showcase {
      object-fit: cover;
      max-width: 710px;
      width: 100%;
      height: 380px;
    }
  }
  .crop-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 380px;
    margin-bottom: 16px;
    // overflow: hidden;
    border: 2px dashed #000000;
    flex-wrap: wrap;
    flex-direction: column;

    input {
      padding: 10px 0;
    }
  }

  .crop-action {
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }

  .add-media-card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    min-height: 380px;
    flex-direction: column;

    // margin-bottom: 12px;
    .fa-cloud-upload-alt {
      color: variables.$secondary;
      font-size: 48px;
      margin-bottom: 16px;
    }
    .bold {
      font-weight: 700;
    }
    .thumbnail-title{
      color: #222222;
      margin-bottom: 16px;
    }
    .thumbnail-title span {
      color: #E7424B;
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .gallery-thumbnails {
    display: flex;
    flex-wrap: wrap;
    .thumbnail-container {
      position: relative;
      margin-right: 10px;
      cursor: pointer;

      .delete-container {
        position: absolute;
        cursor: pointer;
        top: -7px;
        right: -7px;
        background-color: white;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        .fa-minus-circle {
          color: red;
        }
      }
      img {
        height: 40px;
        width: 45px;
        object-fit: cover;
      }
    }

    .gallery-video-container {
      position: relative;
      .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
    }

    .add-new-media {
      background-color: #eaeaea;
      width: 45px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .fa-plus {
        color: variables.$secondary;
      }
    }
  }
}

.edit-amount-raised {
  margin-bottom: 16px;
  .campaign-member-details {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .member-image-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .member-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.55);
        z-index: 1;
      }
      .fa-camera {
        position: absolute;
        z-index: 2;
        font-size: 14px;
      }
    }

    p {
      color: #444444;
      font-size: 16px;
      margin: 0 16px;
    }
  }

  .campaign-member-list {
    margin-bottom: 24px;
    .campaign-single-member {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .remove-member-container {
        border-radius: 50%;
        border: 1px solid #bf0303;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .fa-minus {
          font-size: 10px;
          color: #bf0303;
        }
      }
    }
    .add-campagin-member {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .add-member-container {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #eaeaea;
        border-radius: 50%;
        .fa-plus {
          color: variables.$secondary
        }
      }
      .fa-link {
        color: #797979;
      }
    }
  }

  .amount-raised {
    display: flex;
    align-items: center;
    p {
      color: #666666;
      font-size: 16px;
    }
    .amount {
      font-size: 26px;
      font-weight: 700;
      color: #999999;
      border-bottom: 1px dashed #000000;
      margin-right: 8px;
    }
    .total-amount-container {
      width: auto;
    }
  }

  .support {
    display: flex;
    justify-content: space-between;
    .supporter {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    p {
      color: #666666;
      span {
        font-size: 20px;
        font-weight: 700;
      }
      .supporters-number {
        color: #999999;
        margin-right: 5px;
      }
      .days {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
      }
      .remaining-days {
        color: #161616;
        margin-right: 5px;
      }
    }
  }
}

.edit-story {
  .story-content-editor {
    padding-bottom: 24px;
    border-bottom: 1px solid #979797;
    margin-bottom: 24px;
    .ql-editor {
      font-family: variables.$primaryFont;
      font-size: 16px;
      h1,
      h2,
      h3 {
        margin: 0 0 15px;
      }
      h1 {
        font-size: 2em;
      }
      h2 {
        font-size: 1.5em;
      }
      p {
        margin: 0 0 15px;
      }
      img,
      video {
        max-width: 100%;
        margin: 0 0 15px;
      }
      iframe {
        width: 100%;
        height: 340px;
        margin: 0 0 15px;
      }
      ul,
      ol {
        margin: 0 0 15px;
        padding: 0;
      }
      p ul,
      p ol {
        margin: 10px 0 0;
      }
      blockquote {
        border-left: 6px solid #999999;
        margin: 20px 10px 20px 0;
        padding: 8px 10px;
      }
      blockquote:before {
        color: #999999;
        content: open-quote;
        font-size: 3em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
      }
      blockquote:after {
        color: #999999;
        content: close-quote;
        font-size: 3em;
        line-height: 0.1em;
        margin-left: 0.1em;
        vertical-align: -0.6em;
      }
      blockquote p {
        display: inline;
      }
    }
  }
}

.edit-faqs {
  h3 {
    border-bottom: 1px solid #979797;
    padding-bottom: 12px;
    margin-top: 0;
    margin-bottom: 24px;
  }
  .single-faq {
    margin-bottom: 36px;
    .faq-question {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .faq-answer {
      display: flex;
      align-items: flex-start;
    }

    .bold {
      margin-right: 10px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .add-question {
    text-align: center;
    color: variables.$secondary;
    margin-bottom: 48px;
  }
}

.edit-updates {
  .add-update {
    text-align: center;
    margin-bottom: 36px;
  }
  .updates-gallery {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .update-gallery-image-container {
      position: relative;
      margin-right: 10px;

      .update-gallery-image {
        width: 56px;
        height: 56px;
        object-fit: cover;
      }
      .delete-container {
        position: absolute;
        cursor: pointer;
        right: -7px;
        top: -7px;
        z-index: 20;
        background-color: white;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        .fa-minus-circle {
          color: red;
        }
      }
    }

    .updates-gallery-loader {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .add-image {
      border: 1px solid #f2f2f2;
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        width: 50px;
      }
    }
    .fa-cloud-upload-alt {
      color: variables.$secondary;
      font-size: 30px;
    }
  }

  .single-update {
    margin-bottom: 46px;
    .update-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgb(133, 133, 133);
      padding-bottom: 10px;
      .creator-deatils {
        p {
          font-size: 16px;
          color: #444444;
          line-height: 16px;
          font-weight: 700;

          span {
            font-size: 14px;
            color: #666666;
            font-weight: 600;
          }
        }
      }

      .serial-number {
        width: 40px;
        height: 40px;
        background-color: variables.$primary;
        p {
          color: white;
        }
      }
    }

    .updates-validation-message {
      display: flex;
      align-items: center;
      margin-top: 4px;
      color: red;

      p {
        color: red;
      }
    }
  }
}

.hideDate {
  // display: none;
  visibility: hidden;
}

.thumbnailModalWrap{
  display: flex;
  flex-direction: column;
  width: 75vw;
  margin: auto;
  height: 60vh;
  background-color: white;
  position: absolute;
  transform: translate(-50% , -50%);
  top: 50%;
  left: 50%;

.thumbnailModalCloseWrap{
  position: absolute;
  right: 24px;
  top: 24px;
}
.thumbnailContainer{
  display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 32px; 
    box-sizing: border-box;

  .thumbnailImageWrap{
    border: 1px solid black;
    height: 70%;
    width: 27vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploadThumbnailWrap{
    cursor: pointer;
  }
}
.cropThumbnailContainer{
  position: absolute;
  .cropThumbnailBtnContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 20%;
    gap: 16px;
    margin-top: 16px;
    .thumbnailButton{
      width: 90px;
    }
  }
}
@media only screen and (max-width: 767px) {
  width: 90%;
  height: 60%;
  .thumbnailModalCloseWrap{
    right: 0px;
    top: 0px;
  }
  .thumbnailContainer{
    flex-direction: column;
    gap:16px ;
    padding: 36px;
    .thumbnailImageWrap{
      width: 100%;
    }
  }
  .cropThumbnailContainer{
    .cropThumbnailBtnContainer{
      width: 80%;
      .thumbnailButton{
        width: 120px;
      }
    }
  }
}
}
@media only screen and (max-width: 767px) {
}
.date {
  display: flex;
  align-items: center;
  i {
    margin-right: 10px;
  }
}
.react-datepicker__triangle {
  left: 15px !important;
}
// .react-datepicker__day {
// }
.react-datepicker__day--selected {
  background-color: #dd0000 !important;
}
.react-datepicker-popper {
  // left: initial !important;
  right: 27px !important;
}
.react-datepicker-popper .react-datepicker__triangle {
  display: none;
}
@media only screen and (max-width: 1279px) {
  .edit-mode-title {
    background-color: #444444;
    // height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin-right: 0;
      margin-bottom: 4px;
      color: #fff;
      text-align: center;
    }
  }
}
.ql-container.ql-snow {
  .ql-editor {
    min-height: 100px;
  }
}
